
import {
  JsUserProfile,
  SignInState,
  SignInTypes,
} from "@/store/modules/auth/auth.types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

type NavItem = {
  name: string;
  icon: string;
  to: string;
  children?: NavItem[];
};

const Auth = namespace("Auth");

@Component({
  components: {},
})
export default class HomeView extends Vue {
  public drawer = true;
  public mini = false;
  public items: NavItem[] = [
    { name: "Dashboard", icon: "mdi-view-dashboard", to: "/home/dashboard" },
    {
      name: "Tickets",
      icon: "mdi-ticket-confirmation",
      to: "/home/tickets",
    },
    {
      name: "Analytics",
      icon: "mdi-chart-areaspline",
      to: "/home/analytics/user",
    },
    {
      name: "Reports",
      icon: "mdi-notebook",
      to: "/home/reports",
      children: [
        {
          name: "General Report",
          icon: "mdi-account-group",
          to: "/home/reports/general",
        },
        // {
        //   name: "Activities Analytics",
        //   icon: "mdi-account-group",
        //   to: "/home/analytics/activities",
        // },
      ],
    },
    {
      name: "Admin",
      icon: "mdi-security",
      to: "/home/admin",
      children: [
        {
          name: "Users Control",
          icon: "mdi-account-group",
          to: "/home/admin/user-control",
        },
        {
          name: "Products",
          icon: "mdi-store",
          to: "/home/admin/products",
        },
        {
          name: "Site Stations",
          icon: "mdi-store-marker",
          to: "/home/admin/site-stations",
        },
        {
          name: "Site Zones",
          icon: "mdi-store-marker",
          to: "/home/admin/site-zones",
        },
        {
          name: "Departments",
          icon: "mdi-account-group",
          to: "/home/admin/departments",
        },
        {
          name: "System Roles",
          icon: "mdi-account-group",
          to: "/home/admin/system-roles",
        },
        {
          name: "Issue Priority",
          icon: "mdi-account-group",
          to: "/home/admin/issue-classifications",
        },
        {
          name: "Ticket Categories",
          icon: "mdi-account-group",
          to: "/home/admin/ticket-categories",
        },
      ],
    },
  ];

  @Auth.State("userProfile")
  public userProfile?: JsUserProfile;

  @Auth.Action(SignInTypes.SIGN_OUT)
  public signOut!: () => Promise<void>;

  @Auth.State("signOut")
  public signOutState!: SignInState;

  public dialog = false;

  async signOutOfSystem(): Promise<void> {
    if (this.signOutState.loading) return;

    await this.signOut();
  }

  toggleSignOut(signOut: boolean): void {
    if (this.signOutState.loading) return;
    this.dialog = signOut;
  }
}
